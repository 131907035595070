import React from "react"
import parse from "html-react-parser"
import {GatsbyImage} from 'gatsby-plugin-image';
import Layout from "@components/layout"

// Import images
import eye_catch from "@images/eye_catch.png" // アイキャッチ画像元データ

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-post.json";
import langJa from "@content/locales/ja/blog-post.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";

import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定


const MarketsPost = ({ pageContext}) => {
  
  // アーカイブ基本設定
  let categUri = pageContext.selectType; // URLになっている部分

  // 言語別設定
  const setLang = pageContext.selectLang;
  let postLang, langUrl, date
  if ( setLang === 'en' ) {
    postLang = langEn;
    langUrl = '/';
    date = pageContext.data.date;
  } else {
    postLang = langJa;
    langUrl = '/' + setLang + '/';
    date = formatDate(pageContext.data.date);
  }

  // Seo情報設定
  const seoTag = SeoContents( 
    categUri, setLang, 
    parse(pageContext.data.title), 
    pageContext.seo.metaDesc !== ''? pageContext.seo.metaDesc: parse(pageContext.data.excerpt)[0].props.children[0],
    postLang[ pageContext.selectType + "-page-keywords"],
    "post", pageContext.slug 
  );
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  
  // ブログ別の翻訳変数設定
  let title, txt1, txt2 ; 
  title = postLang[pageContext.selectType + "-page-title"];
  txt1 = postLang[pageContext.selectType + "-txt1"];
  txt2 = postLang[pageContext.selectType + "-txt2"];
   
  // アイキャッチ画像設定
  const featuredImage = {
    data: pageContext.data.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: pageContext.data.featuredImage?.node?.alt || ``,
  }
  let eyecatchData, eyecatchType;
  eyecatchType =  marketsEyecatch(date)
  eyecatchData = pageContext.data.featuredImage ? 
    // wordpressで設定あり（手動設定）
    <>
      {featuredImage?.data && (<GatsbyImage image={featuredImage.data} alt={featuredImage.alt}/>)}
    </> : 
    // wordpressで設定なし（自動出力）
    eyecatchType ;

  return (
  <>
    {seoTag}
    <Layout>
      <article className="blog-post" itemScope itemType="http://schema.org/markets" langType={setLang}>
        <div className="FV pt-5 pb-8 bg-fixed">
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
            <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{title}</h1>
            <div className="text-base">
                <p>{txt1}{txt2}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-xl mx-auto md:pt-0 pt-6 md:max-w-5xl px-6">
          <div className="text-left md:mt-11 mt-5 h-1/2 max-w-xl mb-0">
            <h2 className="tracking-wide text-[26px] font-semibold md:text-[40px] mb-10" itemProp="headline">{parse(pageContext.data.title)}</h2>
          </div>
        </div>
        <div className="md:max-w-5xl pb-4 px-6 mx-auto">
          <div className=" my-0" style={{ maxWidth: 600 }}>
            {eyecatchData}
          </div>
        </div>
        <div className="md:px-0 px-6">
          <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
            {!!pageContext.data.content && (
              <section itemProp="newsBody">{parse(pageContext.data.content)}</section>
            )}
          </div>
        </div>
      </article>

      <div className="Feature pt-5 mt-10 bg-fixed">
        {/* ページネーション */}
        <div className="flex px-5 text-[11px] md:text-sm">
          <nav className="blog-post-nav w-full my-0 mx-auto" style={{ maxWidth: 976 }}>
            <ul style={{ display: `flex`, flexWrap: `wrap`, justifyContent: 'center', alignItems: 'center', listStyle: `none`, padding: 0 }}>
              <li className="mb-0 text-left" style={{ width: '33%' }}>
                {pageContext.previousSlug? <a href={`${langUrl}${categUri}/${pageContext.previousSlug}/`} className='' rel="prev">＜ {postLang["post-previousPage"]}</a>:<></>}
              </li>
              <li className="mb-0 text-center" style={{ width: '33%' }}>
                <a href={`${langUrl}${categUri}/1/`} className="">{postLang["post-archivePage"]}</a>
              </li>
              <li className="mb-0 text-right" style={{ width: '33%', textAlign: 'right' }}>
                {pageContext.nextSlug? <a href={`${langUrl}${categUri}/${pageContext.nextSlug}/`} className='' rel="next">{postLang["post-nextPage"]} ＞</a>:<></>}
              </li>
            </ul>
          </nav>
        </div>
        
        {/* 口座開設ボタン */}
        <div className="mt-5 py-14 py-14 md:py-10 md:py-10">
          <div className="md:mx-auto max-w-5xl flex justify-center">
            <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
          </div>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default MarketsPost

// マーケット分析アイキャッチ生成
function marketsEyecatch(date) {
	let setHtml =
	<>
		<div className={"w-full bg-center bg-cover h-[55%]"}
		style={{
			backgroundImage: `url(${eye_catch})`,
			aspectRatio: "25/18.5",
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat'
			}}>
			<div className="w-full h-full flex justify-center items-center">
				<div className="p-3 text-center ">
					<p style={{fontSize : 32}} className={"text-[32px] tracking-widest"}>{date}</p>
					<p style={{fontSize : 24}} className={"text-[24px] tracking-widest text-[red]"}>Markets Analysis</p>
				</div>
			</div>
		</div>
  </>;
	return setHtml
}

// 日時形式変換関数(日本語用)
function formatDate(dateString) {
	// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const date = new Date(dateString);
	const year = date.getFullYear();
	const monthIndex = date.getMonth();
	// const month = months[monthIndex];
	const day = date.getDate();
	return `${year}年${monthIndex + 1}月${day}日`;
}